<template>
  <section id="app-task">
    <h1 class="title ">
      App Task Generator
    </h1>
    <div class="myRow row">
      <div class="contain">
        <div
          class="intro"
        > <strong>To generate tasks, please enter the name of your application:</strong> </div>
        <div class="app-info">
          <input
            id="appname"
            v-model="appname"
            type="text"
            value=""
            class="form-control input col-sm-8"
          >
          <!-- <b-form-group
            label="Basic Input"
            label-for="basicInput"
          >
            <b-form-input
              id="basicInput"
              placeholder="Enter the name of the app"
            />
          </b-form-group> -->
          <!-- <div class="selects">
            <b-form-select
              v-model="selected"
              :options="designerOptions"
              class="designer"
            /> -->
          <!-- <b-form-group
            label="Basic Input"
            label-for="basicInput"
          >
            <b-form-input
              id="basicInput"
              placeholder="Enter the name of the app"
            />
          </b-form-group> -->
          <!-- <b-form-select
              v-model="selected"
              :options="accountOptions"
              class="account"
            />
          </div> -->
          <b-button
            id="btn-task"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            class="btn btn-primary"
            variant="primary"
          >
            Submit
          </b-button>
        </div>
        <b-table
          :items="items"
          :fields="fields"
          striped
          responsive="sm"
        >
          <template #cell(copy)="row">
            <b-button
              size="sm"
              class="btn btn-primary mb-2 copyButtons"
              @click="copyF($event)"
            >
              Copy
            </b-button>
            <b-form-checkbox
              v-model="row.detailsShowing"
              @change="row.toggleDetails"
            />
          </template>
        </b-table>
      </div>
    </div>
  </section>
</template>

<script>
import {
  BFormSelect, BTable,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BTable,
    BFormSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      selected: null,
      designerOptions: [
        { value: null, text: 'Designer' },
        { value: 'a', text: 'ABDELILAH' },
        { value: 'b', text: 'KHAOULA' },
      ],
      accountOptions: [
        { value: null, text: 'Account' },
        { value: 'a', text: 'Nabil' },
        { value: 'b', text: 'Majida Mourchid' },
        { value: 'b', text: 'Jad App' },
      ],
      appname: ' ',
      tasksList: [],
      items: [
      ],
    }
  },
  mounted() {
    const btnC = document.getElementById('btn-task')
    const input = document.getElementById('appname')
    input.addEventListener('keypress', e => {
      if (e.key === 'Enter') {
        btnC.click()
      }
    })
    btnC.addEventListener('click', () => {
      setTimeout(() => {
        this.test(this.appname, this.items)
      }, 0)
    })
  },
  methods: {
    copyF(event) {
      const copyButton = event.target
      const textToCopy = copyButton.parentElement.previousSibling
      // console.log(copyButton.parentElement.previousSibling.innerHTML)
      const range = document.createRange()
      range.selectNode(textToCopy)
      window.getSelection().removeAllRanges()
      window.getSelection().addRange(range)
      document.execCommand('copy')
      window.getSelection().removeAllRanges()
      // this.$alert('copied')
      this.$vs.notify({
        // title: "success",
        text: 'Text copied',
        // iconPack: "feather",
        // icon: "icon-alert-circle",
        color: 'success',
        time: 2000,
        // fixed: true
      })
    },
    test(appname, tasksList) {
      tasksList.splice(0)
      const tasksName = []
      const button = document.createElement('button')
      button.innerHTML = 'btn'
      fetch('https://webssy.com/items/App_Tasks?access_token=admintoken')
        .then(response => response.json())
        .then(jsondata => {
        // console.log(jsondata["data"]["0"]);
          for (let i = 0; i < jsondata.data.length; i += 1) {
            tasksName.push(jsondata.data[i].task_name + appname)
            tasksList.push({
              task_name: `${jsondata.data[i].task_name} app [${appname}]`, copy: '',
            })
          }
        })
      return tasksName
    },
  },
}
</script>

<style lang="scss">
.contain{
  margin: 0 auto;
  text-align: center;
}
.designer, .account{
  width: 70%;
  display: inline-block;
  margin: 2% 0;
}
table tr td,thead{
  text-align: left;
}
.tasksTable{
  margin: 2% 0;
}
.intro{
  font-size: 1.4rem;
}
#app-task{
  font-size: 1.2rem;
}
.input{
  position: relative;
  left: 50%;
  transform: translate(-50%,0);
  margin: 1rem 0;
}
 .designer , .account{
    text-align: center;
  }
  .input {
    width: 70%;
  }
@media only screen and (max-width: 900px) {
.intro{
 width: 85%;
 margin: 0 auto !important;
}
}

.selects{
  width: 50%;
  margin: 0 auto !important;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.intro{
//  margin: 0 auto !important;
 padding-top: 1rem;
}
#btn-task{
  margin: 1rem 0;
}
</style>
